<template>
    <div id="page-404">
        <div class="icon mdi-biohazard font-size-500"></div>
        <div class="text">Page Not Found</div>
    </div>
</template>

<style lang="scss">
    #page-404 {
        display: block;
        font-size: 2rem;
        text-align: center;
        padding-top: 2rem;
    }
</style>